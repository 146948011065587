<template>
  <b-card-code>
    <div v-if="$can('read', 'Payment')" class="custom-search d-flex">
      <b-button variant="outline-primary">
        <router-link :to="{ name: 'student-payment' }">{{ $t('Payment List') }}</router-link>
      </b-button>
    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <form method="post" action="javascript:void(0)" @submit="payStudentBills">
        <b-row>
          <template>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Student No') }} </span>
                </b-card-text>
                <validation-provider #default="{ errors }" :name="$t('student_id')" rules="required">
                  <b-form-input v-model="student_id" name="student_id" :state="errors.length > 0 ? false : null"
                    placeholder="Student No" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Academic Years') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="academic year" rules="required">
                <select name="academic_year_id" v-model="academic_year_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">{{ aYear.year }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col>
              <b-button class="mt-3" variant="primary" type="button" @click="getStudentInfo">{{ $t('Find') }}</b-button>
            </b-col>
          </template>
          <!-- submit button -->
          <b-col md="12"></b-col>
          <template v-if="student.id">
            <b-col md="12">
              <img :src="imgShow(student.image)" alt="" />
            </b-col>
            <div class="col-md-12 form-group mb-3">
              <label>Name: {{ student.userable ? student.userable.name : '' }}</label><br>
              <label>Flat: {{ versionByID(student.version_id) }}</label><br>
              <label>Class: {{ classByID(student.classes_id) }}</label><br>
              <label>Group: {{ student_groupByID(student.student_group_id) }}</label><br>
              <label>Academic Year: {{ academic_yearByID(student.academic_year_id) }}</label><br>
            </div>
            <div class="col-md-12">
              <center>
                <p style="color:blue">Total Pay: {{ total_payable }}</p>
              </center>
            </div>
            <div class="table-responsive">
              <table id="table" class="table table-striped table-bordered" style="width:100%">
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Fee Type</th>
                    <th>Month</th>
                    <th>Amount</th>
                    <th>Waiver</th>
                    <th>Late Fee</th>
                    <th>Total Pay</th>
                    <th>Due</th>
                    <th>Payment Last Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(fee, key) in fees" :key="key">
                    <td>{{ key + 1 }}</td>
                    <td>{{ studentFeeTypeByID(fee.student_fee_type_id) }}</td>
                    <td>{{ getMonthName(fee.month_year) }}</td>
                    <td>{{ fee.amount }}</td>
                    <td>
                      <p v-for="(waiver, key2) in fee.waivers" :key="key2">
                        Waiver Type: {{ waiver.student_waiver_type ? waiver.student_waiver_type.name : 'TBD' }} <br>
                        {{ 'Waiver Amount: ' + waiver.amount ? waiver.amount : 0 }} <br>
                      </p>
                    </td>
                    <td>
                      <p :style="fee.late_fee_amount === 0 ? 'color:green' : 'color:red'">{{ fee.late_fee_amount }}</p>
                    </td>
                    <td>
                      <p :style="fee.payable === 0 ? 'color:green' : 'color:red'">{{ fee.pay }}</p>
                    </td>
                    <td>
                      <p :style="fee.payable > 0 ? 'color:red' : 'color:green'">{{ fee.payable }}</p>
                    </td>
                    <td>
                      <p :style="fee.payment_last_date >= moment().format('YMD') ? 'color:green' : 'color:red'">
                        {{ fee.payment_last_date }}</p>
                    </td>
                    <td>
                      <label class="checkbox checkbox-outline-success" v-if="(fee.payable) > 0">
                        <input type="checkbox" @click="addPay(fee)" /><span>pay</span><span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
                <tfoot>

                </tfoot>
              </table>
            </div>
            <div class="col-md-12 justify-content-end">
              <button type="submit" style="float: right" class="btn btn-primary">{{ $t('Pay Now') }}</button>
            </div>
          </template>
        </b-row>
      </form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup, BFormInput,
  BRow, BCol, BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: 'StudentPayment',
  components: {
    BCardCode,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      required,
      dir: false,
      total_payable: 0,
      selected_row: {},
      payment_types: [],
      student_id: null,
      academic_year_id: null,
      student: {},
      fees: [],
      inputs: [],
    }
  },
  methods: {
    getMonthName(month_id) {
      var month_name = '';
      if (parseInt(month_id) === 1) {
        month_name = 'January';
      }
      else if (parseInt(month_id) === 2) {
        month_name = 'February';
      }
      else if (parseInt(month_id) === 3) {
        month_name = 'March';
      }
      else if (parseInt(month_id) === 4) {
        month_name = 'April';
      }
      else if (parseInt(month_id) === 5) {
        month_name = 'May';
      }
      else if (parseInt(month_id) === 6) {
        month_name = 'June';
      }
      else if (parseInt(month_id) === 7) {
        month_name = 'July';
      }
      else if (parseInt(month_id) === 8) {
        month_name = 'August';
      }
      else if (parseInt(month_id) === 9) {
        month_name = 'September';
      }
      else if (parseInt(month_id) === 10) {
        month_name = 'October';
      }
      else if (parseInt(month_id) === 11) {
        month_name = 'November';
      }
      else if (parseInt(month_id) === 12) {
        month_name = 'December';
      }

      return month_name;
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    getStudentInfo() {
      this.inputs = [];
      this.student = {};
      this.total_payable = 0;
      let data = new FormData();
      data.append('student_id', this.student_id);

      if (this.academic_year_id)
        data.append('academic_year_id', this.academic_year_id);

      apiCall.post('/find/student/payment/information', data).then((response) => {
        // apiCall.post('/find/student/payment/record', data).then((response)=>{

        if (response.data.status === 'success') {
          this.student = response.data.student;
          this.fees = response.data.fees;
        } else {
          this.$toaster.error(response.data.message);
        }
      }).catch((error) => {
        if (error.response.status == 422) {
          Object.keys(error.response.data.errors).map((field) => {
            this.$toaster.error(error.response.data.errors[field][0]);
          })
        }
        else this.$toaster.error(error.response.data.message);
      })
    },
    payStudentBills() {

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.inputs.length < 1) return;
          let data = new FormData();
          data.append('input', JSON.stringify(this.inputs));
          apiCall.post('/student/payment/information/store', data).then((response) => {
            this.$toaster.success(response.data.message);
            this.inputs = [];
            this.student = {};
            this.total_payable = 0;
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    addPay(fee) {
      var index = this.inputs.findIndex(value => value.assign_student_fee_id === fee.id);
      if (index >= 0) {
        this.removeField(index);
        this.total_payable -= fee.payable;
      } else {
        this.total_payable += fee.payable;
        this.inputs.push({
          'student_id': this.student.id,
          'version_id': this.student.version_id,
          'classes_id': this.student.classes_id,
          'wing_id': this.student.wing_id,
          'student_group_id': this.student.student_group_id,
          'student_fee_type_id': fee.student_fee_type_id,
          'total_amount': fee.payable,
          'fee_amount': (fee.payable - fee.late_fee_amount) > 0 ? (fee.payable - fee.late_fee_amount) : 0,
          'late_fee_amount': fee.late_fee_amount > 0 ? (fee.late_fee_amount - fee.payable) > 0 ? fee.payable : fee.late_fee_amount : 0,
          'assign_student_fee_id': fee.id,
          'academic_year_id': this.student.academic_year_id,
          'month_year': fee.month_year,
          'canteen_bill_id': fee.id,
        }
        );
      }
    },
    removeField(index) {//methods for remove filed
      this.inputs.splice(index, 1)
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['academic_years', 'academic_yearByID', 'student_fee_types',
      'versions', 'versionByID', 'studentFeeTypeByID',
      'classes', 'classByID', 'student_groups', 'student_groupByID']),
  },
  created() {
    if (this.academic_years.length < 1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if (this.versions.length < 1) this.$store.dispatch('GET_ALL_VERSION');
    if (this.classes.length < 1) this.$store.dispatch('GET_ALL_CLASSES');
    if (this.student_groups.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if (this.student_fee_types.length < 1) this.$store.dispatch('GET_ALL_STUDENT_FEE_TYPE');
  },
}
</script>
<style lang="scss" >
</style>